import React, { ChangeEvent, useEffect, useState } from "react"
import { Modal, Spinner } from "react-bootstrap-v5";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import clsx from "clsx";
import InputCNPJ from "../../components/InputCNPJ";
import InputCEP from "../../components/InputCEP";
import AdministradorService from "../../../services/AdministradorService";
import { ICriarClienteRequest } from "../../../interfaces/request/administrador/ICriarClienteRequest";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";
import { IProduto } from "../../../interfaces/responses/administrador/IProduto";
import { IAdminGrupoEmpresarial } from "../../../interfaces/responses/administrador/IAdminGrupoEmpresarial";
import FormInputSelect, { FormInputMultiSelectItems } from "../../components/FormInputSelect";
import { TipoGrupoEmpresarial } from "../../../utils/Constants";
import { IAdminCliente } from "../../../interfaces/responses/administrador/IAdminCliente";
import { IEditarClienteRequest } from "../../../interfaces/request/administrador/IEditarClienteRequest";

interface ClienteModalEditarProps {
    toggleModal: () => void,
    exibirModal: boolean,
    cliente: IAdminCliente
}

const ClienteModalEditar = ({ toggleModal, exibirModal, cliente }: ClienteModalEditarProps) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    // const [autoPreencherUtilizado, setAutoPreencher] = useState<boolean>(false)
    
    const [comboItemsProdutos, setComboItensProdutos] = useState<ComboboxItem[]>([])
    const [produtos, setProdutos] = useState<IProduto[]>([]);

    const [comboItemsGrupoEmpresarial, setComboItensGrupoEmpresarial] = useState<FormInputMultiSelectItems[]>([])
    const [gruposEmpresarial, setGrupos] = useState<IAdminGrupoEmpresarial[]>([]);

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    useEffect(() => {
        carregarComboBox();
        preencherFormulario();
      }, [produtos, gruposEmpresarial])

      async function preencherFormulario() {
        const valoresIniciais = {
            nome: cliente.nome,
            nomeFantasia: cliente.nomeFantasia,
            cnpj: cliente.cnpj,
            cep: cliente.cep,
            logradouro: cliente.logradouro,
            numero: cliente.numero,
            complemento: cliente.complemento,
            bairro: cliente.bairro,
            cidade: cliente.cidade,
            uf: cliente.uf,
            nomeGrupoEmpresarial: cliente.nomeGrupoEmpresarial,
            idGrupoEmpresarial: cliente.idGrupoEmpresarial,
            idProduto: cliente.idProduto,
            nomeImpressoCartao: cliente.nomeImpressoCartao
        } as any;
        
        formik.resetForm({
            values: valoresIniciais
        });
    }

    async function carregarDados(){
        try {
            setLoading(true)
            
            await carregarProdutos();

            await carregarGruposEmpresarial();
          } catch (error) {
            Swal.fire({
              icon: 'error',
              title: 'Não foi possivel carregar os Produtos',
              text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
              showConfirmButton: true,
            })
          } finally {
            setLoading(false)
          } 

        async function carregarProdutos() {
            const { data } = await AdministradorService.obterProdutos();

            setProdutos(data);
        }

        async function carregarGruposEmpresarial() {
            const { data } = await AdministradorService.obterGruposEmpresarial();

            setGrupos(data);
        }
    }
    
    async function carregarComboBox(){
        let itens: ComboboxItem[] = [];
        produtos?.map((p) => itens.push({ value: p.id, text: p.descricao }));
        setComboItensProdutos(itens);

        let itens2: FormInputMultiSelectItems[] = [];
        const grupos = gruposEmpresarial.filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO);
        grupos?.map((p) => itens2.push({ value: p.id, label: p.nome }));
        setComboItensGrupoEmpresarial(itens2);
        setLoading(false);
    }
   
    const clienteValidations = Yup.object().shape({
        nome: Yup.string().max(200, 'Maximo 200 caracteres').required('Nome é obrigatório'),
        nomeFantasia: Yup.string().max(200, 'Maximo 200 caracteres').required('Nome Fantasia é obrigatório'),
        cnpj: Yup.string().max(14, 'Maximo 14 caracteres').min(14, 'Minimo 14 caracteres').required('CNPJ é obrigatório'),
        cep: Yup.string().max(8, 'Maximo 8 caracteres'),
        logradouro: Yup.string().max(255, 'Maximo 255 caracteres'),
        numero: Yup.string().max(100),
        complemento: Yup.string().max(100, 'Maximo 100 caracteres'),
        bairro: Yup.string().max(150, 'Maximo 150 caracteres'),
        cidade: Yup.string().max(255, 'Maximo 255 caracteres'),
        uf: Yup.string().max(2, 'Maximo 2 caracteres'),
        idProduto: Yup.string().required('Produto é obrigatório'),
        nomeGrupoEmpresarial: Yup.string().test('validarGrupo', 'Grupo empresarial é obrigatório', async (value) =>{
            if(value || formik.values.idGrupoEmpresarial){
                return true;
            }

            return false;
        }),
        idGrupoEmpresarial: Yup.string().test('validarGrupo', 'Grupo empresarial é obrigatório', async (value) =>{
            if(value || formik.values.nomeGrupoEmpresarial){
                return true;
            }

            return false;
        })
    })
    
    const valoresIniciais = {
        nome: '',
        nomeFantasia: '',
        cnpj: '',
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
        nomeGrupoEmpresarial: '',
        idGrupoEmpresarial: '',
        idProduto: '',
        nomeImpressoCartao: ''
    } as any;
    
    const formik = useFormik({
        initialValues: valoresIniciais,
        validationSchema: clienteValidations,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                
                let clienteEditar: IEditarClienteRequest =
                {
                    id: cliente.id,
                    nome: values.nome,
                    nomeFantasia: values.nomeFantasia,
                    cnpj: values.cnpj,
                    cep: values.cep,
                    logradouro: values.logradouro,
                    numero: values.numero,
                    complemento: values.complemento,
                    bairro: values.bairro,
                    cidade: values.cidade,
                    uf: values.uf,
                    idGrupoEmpresarial: values.idGrupoEmpresarial,
                    nomeGrupoEmpresarial: values.nomeGrupoEmpresarial,
                    idProduto: values.idProduto,
                    nomeImpressoCartao: values.nomeImpressoCartao
                }
                
                let data = await AdministradorService.editarCliente(clienteEditar);
                
                Swal.fire({
                            icon: 'success',
                            text: `Cliente alterado com sucesso`,
                            showConfirmButton: true,
                        })
                limparFechar();
            } catch (error) {

                Swal.fire(
                     Array.isArray(error) ? error[0] : `Falha ao alterar, tente novamente`
                );
            }
            finally {
                setLoading(false);
            } 
        }
    });

    // async function enriquecimentoDadosCNPJ(){
    //     setLoading(true);
    //     setAutoPreencher(true);
    //     let cnpj = formik.values.cnpj;
    //     let request = await AdministradorService.enriquecerDados(cnpj);        
    //     formik.setFieldValue('nome', request.data.nome);
    //     formik.setFieldValue('nomeFantasia', request.data.fantasia);
    //     formik.setFieldValue('cep', request.data.cep);
    //     formik.setFieldValue('logradouro', request.data.logradouro);
    //     formik.setFieldValue('numero', request.data.numero);
    //     formik.setFieldValue('complemento', request.data.complemento);
    //     formik.setFieldValue('bairro', request.data.bairro);
    //     formik.setFieldValue('cidade', request.data.municipio);
    //     formik.setFieldValue('uf', request.data.uf);        
    //     setLoading(false);
    // }

    async function buscarCep(){
        setLoading(true);
        let cep = formik.values.cep;
        let request = await AdministradorService.buscarCep(cep);        
        formik.setFieldValue('logradouro', request.logradouro);
        formik.setFieldValue('numero', request.numero);
        formik.setFieldValue('complemento', request.complemento);
        formik.setFieldValue('bairro', request.bairro);
        formik.setFieldValue('cidade', request.localidade);
        formik.setFieldValue('uf', request.uf);        
        setLoading(false);
    }

    // function isDisabled(cnpj: string) {
    //     try {

    //       if(autoPreencherUtilizado) return true;

    //       if(cnpj == '') return true;

    //       let isInvalid = formik.errors.cnpj !== undefined;

    //       return isInvalid;

    //     } catch (err) {
          
    //       return true;
    //     }
    // }

    function closeModal(){
        Swal.fire({
            title: 'Deseja fechar a tela de alteração?',
            text: `Ao sair você perderá todas as alterações pendentes`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'CONTINUAR ALTERAÇÃO',
            confirmButtonText: 'SAIR',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-primary'
            }
            
          }).then((result) => {
            if (result.isConfirmed) {
                limparFechar()
            }
        })
    }

    function limparFechar() {
        formik.resetForm();
        setIsCheckboxChecked(false);
        toggleModal();
    }

    async function handleCheckBox(){
        setIsCheckboxChecked(!isCheckboxChecked);
        await formik.setFieldValue('idGrupoEmpresarial', '');
        await formik.setFieldValue('nomeGrupoEmpresarial', '');
    }

    return <>
        <Modal size='xl' centered={true} show={exibirModal} onHide={closeModal} onShow={carregarDados}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Editar Cliente</h5>
                    <div onClick={() => closeModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                {isLoading ? (
                    <Spinner animation='border' />
                ) : (
                    <>
                        <form className="row">
                            <h3 className="required">Informações básicas</h3>
                            <div className="row mt-2">
                                <div className="col-md-4 mb-5">
                                    <label className='form-label fw-bold required'>CNPJ</label>
                                    <div className="input-group">
                                        <InputCNPJ 
                                            id="form-cnpj"
                                            handlechange={async (e: ChangeEvent<HTMLInputElement>) => {
                                            e.target.focus()
                                            await formik.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                                            await formik.setFieldTouched(e.target.name);

                                            await formik.setFieldValue('login', e.target.value.replace(/[^\d]/g, ''));
                                            await formik.setFieldTouched('login');
                                            }}
                                            value={formik.values.cnpj}
                                            name="cnpj"
                                            placeholder="CNPJ"
                                            className={clsx(
                                                'form-control',
                                                {
                                                    'is-invalid': formik.touched.cnpj && formik.errors.cnpj,
                                                },
                                                {
                                                    'is-valid': formik.touched.cnpj && !formik.errors.cnpj,
                                                }
                                            )}
                                        />
                                    </div>
                                    {formik.touched.cnpj && formik.errors.cnpj && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                    <span role='alert'>{formik.errors.cnpj}</span>
                                                </div>
                                            </div>
                                    )}
                                </div>
                                <div className="col-md-4 mb-5">
                                    <label className='form-label fw-bold required'>Selecione o Produto</label>
                                    <ComboBox items={comboItemsProdutos} name='idProduto' onChange={async (event: React.ChangeEvent<HTMLSelectElement>)=>{
                                        event.target.focus()
                                        let id = event.target.value;
                                        let produtoselect = produtos?.find(v => v.id === id);
                                        if(!produtoselect) return;
                                        await formik.setFieldValue(event.target.name, produtoselect.id);
                                        await formik.setFieldTouched(event.target.name);

                                    }} value={formik.values.idProduto} 
                                    className={clsx(
                                        'form-control',
                                        {
                                            'is-invalid': formik.touched.idProduto && formik.errors.idProduto,
                                        },
                                        {
                                            'is-valid': formik.touched.idProduto && !formik.errors.idProduto,
                                        }
                                    )} 
                                    />
                                    {formik.touched.idProduto && formik.errors.idProduto && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.idProduto}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-4 mb-5">
                                    <label className='form-label fw-bold required'>Selecione o Grupo Empresarial</label>
                                    {isCheckboxChecked ? (
                                        <>
                                        <input {...formik.getFieldProps('nomeGrupoEmpresarial')} 
                                            type="text" 
                                            placeholder="Nome do Grupo Empresarial"
                                            className={clsx(
                                                'form-control',
                                                {
                                                    'is-invalid': formik.touched.nomeGrupoEmpresarial && formik.errors.nomeGrupoEmpresarial,
                                                },
                                                {
                                                    'is-valid': formik.touched.nomeGrupoEmpresarial && !formik.errors.nomeGrupoEmpresarial,
                                                }
                                            )} id="form-nomeGrupoEmpresarial" />
                                            {formik.touched.nomeGrupoEmpresarial && formik.errors.nomeGrupoEmpresarial && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.nomeGrupoEmpresarial}</span>
                                                    </div>
                                                </div>
                                            )}

                                         <input {...formik.getFieldProps('nomeImpressoCartao')} 
                                            type="text" 
                                            placeholder="Nome a ser impresso no cartão"
                                            className={clsx(
                                                'form-control mt-2',
                                                {
                                                    'is-invalid': formik.touched.nomeImpressoCartao && formik.errors.nomeImpressoCartao,
                                                },
                                                {
                                                    'is-valid': formik.touched.nomeImpressoCartao && !formik.errors.nomeImpressoCartao,
                                                }
                                            )} id="form-nomeImpressoCartao" />
                                            {formik.touched.nomeImpressoCartao && formik.errors.nomeImpressoCartao && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.nomeImpressoCartao}</span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                        <FormInputSelect 
                                            disabled={formik.isSubmitting} 
                                            values={comboItemsGrupoEmpresarial} 
                                            formik={formik} 
                                            className="col " 
                                            propName="idGrupoEmpresarial"
                                            label='Grupo Empresarial' 
                                            loading={isLoading}
                                            labelIsVisible={false}/>

                                        {formik.touched.idGrupoEmpresarial && formik.errors.idGrupoEmpresarial && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.idGrupoEmpresarial}</span>
                                                    </div>
                                                </div>
                                        )}
                                        </>
                                    )}
                                    <div className="mt-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={() => handleCheckBox()} />
                                            <label className="form-check-label">
                                                Criar Novo Grupo Empresarial
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-5">
                                <label htmlFor="form-nome" className="form-label required">Nome</label>
                                <input {...formik.getFieldProps('nome')} placeholder="Nome" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.nome && formik.errors.nome,
                                    },
                                    {
                                        'is-valid': formik.touched.nome && !formik.errors.nome,
                                    }
                                )} id="form-nome" />
                                {formik.touched.nome && formik.errors.nome && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.nome}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-6 mb-5">
                                <label htmlFor="form-nome-fantasia" className="form-label required">Nome Fantasia</label>
                                <input {...formik.getFieldProps('nomeFantasia')} placeholder="Nome Fantasia" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.nomeFantasia && formik.errors.nomeFantasia,
                                    },
                                    {
                                        'is-valid': formik.touched.nomeFantasia && !formik.errors.nomeFantasia,
                                    }
                                )} id="form-nome" />
                                {formik.touched.nomeFantasia && formik.errors.nomeFantasia && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.nomeFantasia}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <h3>Endereço</h3>
                            <div className="row mt-2">
                                <div className="col-md-3 mb-5">
                                    <label className='form-label fw-bold'>CEP</label>
                                    <div className="input-group">
                                        <InputCEP 
                                            id="form-cep"
                                            handlechange={async (e: ChangeEvent<HTMLInputElement>) => {
                                            e.target.focus()
                                            await formik.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                                            await formik.setFieldTouched(e.target.name);
                                            }}
                                            value={formik.values.cep}
                                            name="cep"
                                            placeholder="CEP"
                                            className={clsx(
                                                'form-control',
                                                {
                                                    'is-invalid': formik.touched.cep && formik.errors.cep,
                                                },
                                                {
                                                    'is-valid': formik.touched.cep && !formik.errors.cep,
                                                }
                                            )}
                                            />
                                            {formik.touched.cep && formik.errors.cep && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                        <span role='alert'>{formik.errors.cep}</span>
                                                    </div>
                                                </div>
                                            )}
                                        <button className="btn btn-primary btn-sm" 
                                            disabled={!formik.values.cep}
                                            onClick={async (event) => 
                                                {
                                                    event.preventDefault();
                                                    buscarCep()
                                                }
                                            }>
                                            BUSCAR CEP
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 mb-5">
                                <label htmlFor="form-logradouro" className="form-label">Logradouro</label>
                                <input {...formik.getFieldProps('logradouro')} placeholder="Logradouro" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.logradouro && formik.errors.logradouro,
                                    },
                                    {
                                        'is-valid': formik.touched.logradouro && !formik.errors.logradouro,
                                    }
                                )} id="form-logradouro" />
                                {formik.touched.logradouro && formik.errors.logradouro && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.logradouro}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-3 mb-5">
                                <label htmlFor="form-numero" className="form-label">Número</label>
                                <input {...formik.getFieldProps('numero')} placeholder="Número" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.numero && formik.errors.numero,
                                    },
                                    {
                                        'is-valid': formik.touched.numero && !formik.errors.numero,
                                    }
                                )} id="form-numero" />
                                {formik.touched.numero && formik.errors.numero && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.numero}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-3 mb-5">
                                <label htmlFor="form-complemento" className="form-label">Complemento</label>
                                <input {...formik.getFieldProps('complemento')} placeholder="Complemento" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.complemento && formik.errors.complemento,
                                    },
                                    {
                                        'is-valid': formik.touched.complemento && !formik.errors.complemento,
                                    }
                                )} id="form-complemento" />
                                {formik.touched.complemento && formik.errors.complemento && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.complemento}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-5 mb-5">
                                <label htmlFor="form-bairro" className="form-label">Bairro</label>
                                <input {...formik.getFieldProps('bairro')} placeholder="Bairro" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.bairro && formik.errors.bairro,
                                    },
                                    {
                                        'is-valid': formik.touched.bairro && !formik.errors.bairro,
                                    }
                                )} id="form-bairro" />
                                {formik.touched.bairro && formik.errors.bairro && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.bairro}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            
                            <div className="col-md-5 mb-5">
                                <label htmlFor="form-cidade" className="form-label">Cidade</label>
                                <input {...formik.getFieldProps('cidade')} placeholder="Cidade" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.cidade && formik.errors.cidade,
                                    },
                                    {
                                        'is-valid': formik.touched.cidade && !formik.errors.cidade,
                                    }
                                )} id="form-cidade" />
                                {formik.touched.cidade && formik.errors.cidade && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.cidade}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-2 mb-5">
                                <label htmlFor="form-uf" className="form-label">UF</label>
                                <input {...formik.getFieldProps('uf')} placeholder="UF" type="text" className={clsx(
                                    'form-control',
                                    {
                                        'is-invalid': formik.touched.uf && formik.errors.uf,
                                    },
                                    {
                                        'is-valid': formik.touched.uf && !formik.errors.uf,
                                    }
                                )} id="form-uf" />
                                {formik.touched.uf && formik.errors.uf && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.uf}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </form>
                    </>)
                }
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => closeModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                    <button  disabled={formik.isSubmitting || !formik.isValid} onClick={() => { formik.submitForm() }} type="button" className="btn btn-primary ms-5">
                        {!formik.isSubmitting && <> EDITAR </>}
                        {formik.isSubmitting && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                                Salvando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    </>
}

export default ClienteModalEditar;
