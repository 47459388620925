import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { IAdminCredenciado } from "../../../interfaces/responses/administrador/IAdminCredenciado";
import AdministradorService from "../../../services/AdministradorService";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import InputCNPJ from "../../components/InputCNPJ";
import PainelFiltro from "../../components/PainelFiltro";
import clsx from "clsx";
import * as Yup from 'yup';
import { IAdminCliente } from "../../../interfaces/responses/administrador/IAdminCliente";
import ClienteModalDetalhe from "./ClienteModalDetalhe";
import ClienteModalCriar from "./ClienteModalCriar";
import ClienteModalUsuarios from "./ClienteModalUsuarios";
import FormInputSelect, { FormInputMultiSelectItems } from "../../components/FormInputSelect";
import { TipoGrupoEmpresarial } from "../../../utils/Constants";
import ClienteModalEditar from "./ClienteModalEditar";
                  
export function Clientes() {
  const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
  const [clientes, setClientes] = useState<IAdminCliente[]>([])
  const [clienteSelecionado, setCliente] = useState<IAdminCliente>({} as IAdminCliente)
  const [exibirModalDetalhe, setExibirModalDetalhe] = useState<boolean>(false)
  const [exibirModalUsuario, setExibirModalUsuario] = useState<boolean>(false)
  const [selectGrupos, setGruposItens] = useState<FormInputMultiSelectItems[]>([])

  const [exibirModalEditar, setExibirModalEditar] = useState<boolean>(false)


  useEffect(() => {
    formikFiltro.submitForm()
    carregarGrupos();
  }, [])

  async function carregarGrupos(){
    try {
   
        setLoadingInicial(true)
        
        const { data } = await AdministradorService.obterGruposEmpresarial()

        const grupos = data.filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO);

        grupos?.map((p) => selectGrupos.push({ value: p.id, label: p.nome }));
        
        setGruposItens(selectGrupos);

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os dados',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      }
  }
  
  const valoresIniciaisFiltro = {
    cnpj: '',
    codigo: '',
    idGrupoEmpresarial: ''
  } as any;
  
  const filtroValidacoes = Yup.object().shape({
    cnpj: Yup.string().min(14, "CNPJ deve possuir exatamente 14 caracteres").max(14, "CNPJ deve possuir exatamente 14 caracteres"),
  })
  
  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    validationSchema: filtroValidacoes,
    onSubmit: async ({cnpj, codigo, idGrupoEmpresarial}) => {
      try {
       
        setLoadingInicial(true)
        
        const { data } = await AdministradorService.obterClientes(cnpj, codigo, idGrupoEmpresarial)

        setClientes(data)

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os Credenciados',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }
  });

  function toggleModalCriar(carregarDados?: boolean): void {
    setExibirModalCriar(!exibirModalCriar);
  }

  function toggleModalDetalhe(): void {
    setExibirModalDetalhe(!exibirModalDetalhe);
  }

  function toggleModalUsuario(): void {
    setExibirModalUsuario(!exibirModalUsuario);
  }

  function toggleModalEditar(): void {
    setExibirModalEditar(!exibirModalEditar);
  }

  const colunas = [
    {
      name: 'CNPJ',
      selector: (row: IAdminCliente) => row['cnpj']?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Nome',
      selector: (row: IAdminCliente) => row['nome'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Nome Fantasia',
      selector: (row: IAdminCliente) => row['nomeFantasia'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Grupo',
      selector: (row: IAdminCliente) => row['nomeGrupoEmpresarial'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Produto',
      selector: (row: IAdminCliente) => row['nomeProduto'],
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Ações',
      cell: (cliente: IAdminCliente) => {
        return (
         
            <div className="p-1">
              <button
                  title="Detalhes"
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    setCliente(cliente);
                    setExibirModalDetalhe(true);
                  }}
              >
                
                  <i style={{fontSize: 24}} className="bi bi-three-dots"></i>
              </button>
              <button
                  title="Usuários"
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    setCliente(cliente);
                    setExibirModalUsuario(true);
                  }}
              >
                
                  <i style={{fontSize: 24}} className="bi bi-people-fill"></i>
              </button>
              <button
                  title="Editar"
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    setCliente(cliente);
                    setExibirModalEditar(true);
                  }}
              >
                
                  <i style={{fontSize: 24}} className="bi bi-pencil"></i>
              </button>
            </div>
        )
      },
    }
  ]

  return (
    <>
       <ClienteModalCriar
        toggleModal={toggleModalCriar}
        exibirModal={exibirModalCriar}
      />

      <ClienteModalUsuarios 
        exibirModal={exibirModalUsuario}
        toggleModal={toggleModalUsuario}
        cliente={clienteSelecionado}
      /> 
      
      <ClienteModalDetalhe 
        exibirModal={exibirModalDetalhe}
        toggleModal={toggleModalDetalhe}
        cliente={clienteSelecionado}
      /> 

      <ClienteModalEditar 
        exibirModal={exibirModalEditar}
        toggleModal={toggleModalEditar}
        cliente={clienteSelecionado}
      /> 

      <HeaderContent title="Clientes" >
        <button onClick={() => {
            setExibirModalCriar(true)
          }}  className='btn btn-sm btn-primary me-2'>
            <i className="icon-lg text-white fas fa-plus"></i>
            <span style={{ verticalAlign: 'middle' }}> Novo Cliente</span>
        </button>  
      </HeaderContent>

      <PainelFiltro>
              {
                  <>
                        <div className="col col-md-4">
                          <label className='form-label fw-bold'>CNPJ</label>
                          <InputCNPJ 
                            id="form-cnpj"
                            handlechange={async (e: ChangeEvent<HTMLInputElement>) => {
                              e.target.focus()
                              await formikFiltro.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                              await formikFiltro.setFieldTouched(e.target.name);
                              }}
                              value={formikFiltro.values.cnpj}
                              name="cnpj"
                              placeholder="Busque por CNPJ"
                              className={clsx(
                                'form-control',
                                {
                                    'is-invalid': formikFiltro.touched.cnpj && formikFiltro.errors.cnpj,
                                },
                                {
                                    'is-valid': formikFiltro.touched.cnpj && !formikFiltro.errors.cnpj,
                                }
                            )}
                            />
                            {formikFiltro.touched.cnpj && formikFiltro.errors.cnpj && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formikFiltro.errors.cnpj}</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="col col-md-4">
                          <label className='form-label fw-bold'>Código</label>
                          <input onChange={async (e) =>{ 
                                await formikFiltro.setFieldValue(e.target.name, e.target.value);
                                await formikFiltro.setFieldTouched(e.target.name);
                            }} 
                            value={formikFiltro.values.codigo}
                            name="codigo" 
                            type="text"
                            placeholder="Busque por código"
                            className="form-control"
                            />
                        </div>

                        <FormInputSelect 
                                disabled={formikFiltro.isSubmitting} 
                                values={selectGrupos} 
                                formik={formikFiltro} 
                                className="col col-md-4" 
                                propName='idGrupoEmpresarial' 
                                label='Grupo' />
                  </>
              }
              {
                  <>
                      <button data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                          onClick={formikFiltro.submitForm}
                          disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                          <i className="icon-lg fa fa-filter"></i>
                          Filtrar
                      </button>
                  </>
              }
      </PainelFiltro>
      {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <GridPadrao colunas={colunas} tipo='Clientes' itens={clientes} />
          </div>
        )}
    
    </>
  )
}