import { IAdminCredenciado } from "../interfaces/responses/administrador/IAdminCredenciado";
import { IAdminUsuario } from "../interfaces/responses/administrador/IAdminUsuario";
import { IApiResult } from "../interfaces/responses/common/IApiResult";
import { IEnriquecimentoCnpj } from "../interfaces/responses/administrador/IEnriquecimentoCnpj";
import api from "./Api";
import { ICriarCredenciadoRequest } from "../interfaces/request/administrador/ICriarCredenciadoRequest";
import { IVincularUsuarioCredenciadoRequest } from "../interfaces/request/administrador/IVincularUsuarioCredenciadoRequest";
import { IAdminCliente } from "../interfaces/responses/administrador/IAdminCliente";
import { IPerfil, IProduto } from "../interfaces/responses/administrador/IProduto";
import { IGrupoEmpresarial } from "../interfaces/responses/IAutenticacao";
import { IBuscarcep } from "../interfaces/responses/administrador/IBuscarcep";
import { ICriarClienteRequest } from "../interfaces/request/administrador/ICriarClienteRequest";
import { ITrocarSenhaUsuarioRequest } from "../interfaces/request/administrador/ITrocarSenhaUsuarioRequest";
import { IAdminGrupoEmpresarial } from "../interfaces/responses/administrador/IAdminGrupoEmpresarial";
import { IAdminEditarGrupoEmpresarialRequest } from "../interfaces/request/administrador/IAdminEditarGrupoEmpresarialRequest";
import { IAdminCriarGrupoEmpresarialRequest } from "../interfaces/request/administrador/IAdminCriarGrupoEmpresarialRequest";
import { ICriarUsuarioRequest } from "../interfaces/request/administrador/ICriarUsuarioRequest";
import { IAdminVincularUsuarioClientes } from "../interfaces/request/administrador/IAdminVincularUsuarioClientes";
import { IAdminVincularUsuarioCredenciados } from "../interfaces/request/administrador/IAdminVincularUsuarioCredenciados";
import { IPedidoCreditoResponse } from "../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import { IPedidoCreditoDetalheResponse } from "../interfaces/responses/clientes/IPedidoCreditoDetalheResponse";
import { IAdminConfirmarPedidoEmLote } from "../interfaces/request/administrador/IAdminConfirmarPedidoEmLote";
import { IClienteLoteResponse } from "../interfaces/responses/clientes/ILoteCartaoResponse";
import { ICartaoResponse } from "../interfaces/responses/clientes/ICartaoResponse";
import { IEditarClienteRequest } from "../interfaces/request/administrador/IEditarClienteRequest";
import { IEditarCredenciadoRequest } from "../interfaces/request/administrador/IEditarCredenciadoRequest";

class AdministradorService {
    
    async obterCredenciados(cnpj:string, codigo: string, idGrupoEmpresarial: string): Promise<IApiResult<IAdminCredenciado[]>> {
        let { data: result } = await api.get<IApiResult<IAdminCredenciado[]>>('administrador/obter-credenciados', 
        {
            params: {cnpj: cnpj, codigo: codigo, idGrupoEmpresarial: idGrupoEmpresarial}
        });

        return result;
    }

    async obterClientes(cnpj:string, codigo: string, idGrupoEmpresarial: string): Promise<IApiResult<IAdminCliente[]>> {
        let { data: result } = await api.get<IApiResult<IAdminCliente[]>>('administrador/obter-clientes', 
        {
            params: {cnpj: cnpj, codigo: codigo, idGrupoEmpresarial: idGrupoEmpresarial}
        });

        return result;
    }

    async obterPedidosCredito(idGrupoEmpresarial: string, idCliente: string, codigo: string, status: string, dataDisponibilizacao: string): Promise<IApiResult<IPedidoCreditoResponse[]>> {
        
        let { data: result } = await api.get<IApiResult<IPedidoCreditoResponse[]>>(`administrador/pedido/obter`, {
            params: {idGrupo: idGrupoEmpresarial, idCliente: idCliente, codigo: codigo, status: status, dataCredito: dataDisponibilizacao}
        });

        return result;
    }

    async obterPedidoCreditoDetalhes(idPedidoCredito: string): Promise<IApiResult<IPedidoCreditoDetalheResponse[]>> {
        let { data: result } = await api.get<IApiResult<IPedidoCreditoDetalheResponse[]>>(`administrador/pedido/obter/detalhe/${idPedidoCredito}`);

        return result;
    }
    
    async obterUsuarioNaoVinculadoPorLogin(idCredenciado:string, login:string): Promise<IApiResult<IAdminUsuario>> {
        let { data: result } = await api.get<IApiResult<IAdminUsuario>>('administrador/obter-usuario-nao-vinculado', 
        {
            params: {idClienteOuCredenciado: idCredenciado, login: login}
        });

        return result;
    }

    async obterUsuarioPorId(idUsuario: string): Promise<IApiResult<IAdminUsuario>> {
        let { data: result } = await api.get<IApiResult<IAdminUsuario>>(`administrador/usuario/${idUsuario}`)

        return result;
    }

    async obterUsuariosCredenciado(idCredenciado:string): Promise<IApiResult<IAdminUsuario[]>> {
        let { data: result } = await api.get<IApiResult<IAdminUsuario[]>>('administrador/obter-usuarios-cliente-credenciado', 
        {
            params: {idClienteOuCredenciado: idCredenciado}
        });

        return result;
    }

    async obterProdutos(): Promise<IApiResult<IProduto[]>> {
        let { data: result } = await api.get<IApiResult<IProduto[]>>('administrador/obter-produtos');

        return result;
    }

    async obterPerfis(): Promise<IApiResult<IPerfil[]>> {
        let { data: result } = await api.get<IApiResult<IPerfil[]>>('administrador/obter-perfis');

        return result;
    }

    async obterGruposEmpresarial(): Promise<IApiResult<IAdminGrupoEmpresarial[]>> {
        let { data: result } = await api.get<IApiResult<IAdminGrupoEmpresarial[]>>('administrador/obter-grupos-empresarial');

        return result;
    }

    async obterUsuarios(idCredenciado: string, idCliente: string, login: string): Promise<IApiResult<IAdminUsuario[]>> {
        let { data: result } = await api.get<IApiResult<IAdminUsuario[]>>('administrador/obter-usuarios', 
        {
            params: {idCredenciado: idCredenciado, idCliente: idCliente, login: login}
        });

        return result;
    }

    async obterLotes(idCredenciado: string): Promise<IApiResult<IClienteLoteResponse[]>> {
        let { data: result } = await api.get<IApiResult<IClienteLoteResponse[]>>(`administrador/lote/obter/${idCredenciado}`)
       
        return result;
    }
    
    async obterCartoesSemLote(idCredenciado: string): Promise<IApiResult<ICartaoResponse[]>> {
        let { data: result } = await api.get<IApiResult<ICartaoResponse[]>>(`administrador/lote/obter/${idCredenciado}/cartao/sem-lote`)
       
        return result;
    }

    async obterCartoesPorLote(idCredenciado: string): Promise<IApiResult<ICartaoResponse[]>> {
        let { data: result } = await api.get<IApiResult<ICartaoResponse[]>>(`administrador/lote/obter/${idCredenciado}/cartoes`)
       
        return result;
    }

    async obterArquivoLote(idLote: string, codigo: number): Promise<void> {
        const response = await api.get(`administrador/lote/obter/${idLote}/arquivo`, {
            responseType: 'blob'
        })
        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `lote-${codigo}.txt`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }

    async enriquecerDados(cnpj: string): Promise<IApiResult<IEnriquecimentoCnpj>> {
        let { data: result } = await api.get<IApiResult<IEnriquecimentoCnpj>>(`enriquecimentodados/enriquecimento-cnpj`, 
        {
            params: {cnpj: cnpj}    
        });

        return result;
    }

    async buscarCep(cep: string): Promise<IBuscarcep> {
        let { data: result } = await api.get<IBuscarcep>(`https://viacep.com.br/ws/${cep}/json`);
        
        return result;
    }

    async cadastrarCredenciado(credenciado: ICriarCredenciadoRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`administrador/cadastrar/credenciado`, credenciado);

        return result;
    }

    async cadastrarCliente(cliente: ICriarClienteRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`administrador/cadastrar/cliente`, cliente);

        return result;
    }

    async editarCliente(cliente: IEditarClienteRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`administrador/editar/cliente`, cliente);

        return result;
    }

    async editarCredenciado(credenciado: IEditarCredenciadoRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`administrador/editar/credenciado`, credenciado);

        return result;
    }

    async criarLote(idCredenciado: string): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`administrador/lote/${idCredenciado}`, null);

        return result;
    }

    async criarUsuario(usuario: ICriarUsuarioRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`administrador/cadastrar/usuario`, usuario);

        return result;
    }

    async cadastrarGrupoEmpresarial(grupoEmpresarial: IAdminCriarGrupoEmpresarialRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`administrador/cadastrar/grupo-empresarial`, grupoEmpresarial);

        return result;
    }

    async importarArquivoPedido(idGrupoEmpresarial: string, arquivo: FormData): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`administrador/pedido/importar/${idGrupoEmpresarial}`, arquivo);

        return result;
    }

    async vincularUsuarioCredenciado(vincularUsuarioCredenciado: IVincularUsuarioCredenciadoRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`administrador/vincular/usuario-credenciado`, vincularUsuarioCredenciado);

        return result;
    }

    async redefinirSenha(redefinirSenhaRequest: ITrocarSenhaUsuarioRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`administrador/redefinir/senha`, redefinirSenhaRequest);

        return result;
    }

    async editarGrupoEmpresarial(grupoEmpresarialRequest: IAdminEditarGrupoEmpresarialRequest): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`administrador/grupo/editar`, grupoEmpresarialRequest);

        return result;
    }

    async vincularUsuarioClientes(idUsuario: string, request: IAdminVincularUsuarioClientes): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`administrador/usuario/vincular-clientes/${idUsuario}`, request);

        return result;
    }
    async vincularUsuarioCredenciados(idUsuario: string, request: IAdminVincularUsuarioCredenciados): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`administrador/usuario/vincular-credenciados/${idUsuario}`, request);

        return result;
    }

    async confirmarPedidosEmLote(request: IAdminConfirmarPedidoEmLote): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`administrador/pedido/confirmar`, request);

        return result;
    }
}

export default new AdministradorService();