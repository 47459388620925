import clsx from "clsx";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { ICancelarPedidoCredito } from "../../../../interfaces/request/clientes/ICancelarPedidoCredito";
import { IPedidoCreditoResponse } from "../../../../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import ClienteService from "../../../../services/ClienteService";
import GridPadrao from "../../../components/GridPadrao";
import HeaderContent from "../../../components/HeaderContent";
import { Link } from "react-router-dom";
import FormInputSelect, { FormInputMultiSelectItems } from "../../../components/FormInputSelect";
import * as XLSX from 'xlsx'
import AdministradorService from "../../../../services/AdministradorService";
import FiltroPedido from "../../../components/pedido/FiltroPedido";
import { TipoGrupoEmpresarial } from "../../../../utils/Constants";
import ImportarPedidoModal from "../modal/ImportarPedidoModal";
import ConfirmarPedidoEmLoteModal from "../modal/ConfirmarPedidoEmLoteModal";
import PedidoDetalheModal from "../modal/PedidoDetalheModal";

export function Pedidos() {
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
  const [pedidosCredito, setPedidosCredito] = useState<IPedidoCreditoResponse[]>([])
  const [exibirModalDetalhe, setExibirModalDetalhe] = useState<boolean>(false)
  const [pedidoCreditoSelecionado, setPedidoCredito] = useState<IPedidoCreditoResponse>({} as IPedidoCreditoResponse)
  const [exibirModalImportar, setExibirModalImportar] = useState<boolean>(false)
  const [selectCliente, setClienteItems] = useState<FormInputMultiSelectItems[]>([])
  const [selectGrupos, setGruposItens] = useState<FormInputMultiSelectItems[]>([])
  const [exibirModalConfirmarPedidoEmLote, setExibirModalConfirmarPedidoEmLote] = useState<boolean>(false)

  useEffect(() => {
    CarregarDados();
  }, [])
  
  async function CarregarDados() {
    setLoadingInicial(true)
    await carrecarClientes();
    await carregarGrupos();
    setLoadingInicial(false)
  }

  async function carregarGrupos(){
    try {
   
        const { data } = await AdministradorService.obterGruposEmpresarial()

        const grupos = data.filter(v=> v.tipo !== TipoGrupoEmpresarial.CREDENCIADO);

        grupos?.map((p) => selectGrupos.push({ value: p.id, label: p.nome }));
        
        setGruposItens(selectGrupos);

      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os dados',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
       
      }
  }

  async function carrecarClientes(){
    const { data } = await AdministradorService.obterClientes('', '', '');

    data?.map((p) => selectCliente.push({ value: p.id, label: p.nomeFantasiaCnpj }));
    
    setClienteItems(selectCliente);
  }

  function toggleModalImportar(): void {
    setExibirModalImportar(!exibirModalImportar);
  }

  function toggleModalConfirmarPedidoEmLote(): void {
    if (exibirModalConfirmarPedidoEmLote) {
      formikFiltro.submitForm()
    }
    setExibirModalConfirmarPedidoEmLote(!exibirModalConfirmarPedidoEmLote);
  }

  const valoresIniciaisFiltro = {
    idCliente: '0',
    codigo: '',
    dataDisponibilizacao: '',
    status: '',
    idGrupoEmpresarial: ''
  } as any;

  function limparForm(){
    formikFiltro.resetForm();
  }
  

  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    onSubmit: async ({idGrupoEmpresarial, idCliente, dataDisponibilizacao, codigo, status}) => {
      try {

        setLoadingInicial(true)
        
        const { data } = await AdministradorService.obterPedidosCredito(idGrupoEmpresarial, idCliente, codigo, status, dataDisponibilizacao);
        
        setPedidosCredito(data)
      
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os pedidos',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }
  });
  
  const colunas = [
    {
      name: '# Código',
      selector: (row: IPedidoCreditoResponse) => row['codigo'],
      sortable: true,
      wrap: true
    },
    {
      name: 'Data Cadastro',
      selector: (row: IPedidoCreditoResponse) => row['dataCadastro'],
      sortable: false,
      wrap: true,
    },
    {
      name: 'Cnpj',
      selector: (row: IPedidoCreditoResponse) => row['cnpjCliente'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
      sortable: false,
      wrap: true,
      grow: 2
    },
    {
      name: 'Filial',
      selector: (row: IPedidoCreditoResponse) => row['nomeCliente'],
      sortable: false,
      wrap: true,
      grow: 3
    },
    {
      name: 'Data Disponibilização',
      selector: (row: IPedidoCreditoResponse) => row['dataDisponibilizacaoFormatada'],
      sortable: false,
      wrap: true,
    },
    {
      name: 'Valor',
      selector: (row: IPedidoCreditoResponse) => row['valorFormatado'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Status',
      selector: (row: IPedidoCreditoResponse) => row['status'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Ações',
      cell: (pedidoCredito: IPedidoCreditoResponse) => {
        return (
          <>
            <div className="py-2">
                  <button
                      title="Funcionários do pedido"
                      className='btn btn-sm btn-icon btn-secondary me-2'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={() => {
                        setPedidoCredito(pedidoCredito);
                        setExibirModalDetalhe(true);
                      }}
                  >
                      <i style={{fontSize: 24}} className="bi bi-people-fill"></i>
                  </button>
              </div>
              <div className="py-2">
                  <button
                      title="Imprimir Pedido"
                      className='btn btn-sm btn-icon btn-secondary me-2'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={async () => {
                        await DownloadFile(pedidoCredito);
                      }}
                  >
                      <i style={{fontSize: 24}} className="bi bi-printer-fill"></i>
                  </button>
              </div>
              {
                pedidoCredito.status === 'Rascunho' ? 
                (<>
                    <div className="">
                      <button
                          title="Cancelar pedido de crédito"
                          className='btn btn-sm btn-icon btn-secondary me-2'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          onClick={() => {
                              cancelarPedidoCredito(pedidoCredito);
                          }}
                      >
                        <i style={{fontSize: 24}} className="bi bi-x-square-fill"></i>
                      </button>
                  </div>
                </>)
                :
                (<></>)
              }
              {
                pedidoCredito.status === 'Rascunho' ? 
                (<>
                    <div className="">
                      <button
                          title="Confirmar pedido"
                          className='btn btn-sm btn-icon btn-secondary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          onClick={() => {
                              enviarPedidoCredito(pedidoCredito);
                          }}
                      >
                        <i style={{fontSize: 24}} className="bi bi-box-arrow-up"></i>
                      </button>
                  </div>
                </>)
                :
                (<></>)
              }
          </>
        )
      },
    }
  ]

  async function DownloadFile(pedidoCredito: IPedidoCreditoResponse){
    try {
      setLoadingInicial(true)
      const { data  } = await ClienteService.obterPedidoCreditoDetalhes(pedidoCredito.idCliente, pedidoCredito.id)
      let dataParaPlanilha: any[] = [];
    
      data.forEach(item => {
          dataParaPlanilha.push({
              Nome: item.nome,
              Cpf: item.cpf,
              NumeroCartao: item.numero,
              Cnpj: pedidoCredito.cnpjCliente,
              Filial: pedidoCredito.nomeCliente,
              ValorCredito: item.valor,
              DataCredito: pedidoCredito.dataDisponibilizacaoFormatada,
              Situacao: pedidoCredito.status,
              NumeroPedido: pedidoCredito.codigo
          })
      });

      let worksheet = XLSX.utils.json_to_sheet(dataParaPlanilha);
      let workbook = XLSX.utils.book_new();
      let fileName = `pedido-credito-${pedidoCredito.dataDisponibilizacaoFormatada}-${pedidoCredito.nomeCliente}-${pedidoCredito.cnpjCliente}.ods`;

      XLSX.utils.book_append_sheet(workbook, worksheet, "pedido-credito");

      XLSX.writeFile(workbook, fileName, { bookType: "ods" });
    
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Não foi possivel carregar os Funcionários do pedido',
        text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
        showConfirmButton: true,
      })
    } finally {
      setLoadingInicial(false)
    } 
  } 

  function toggleModalDetalhe(): void {
    setExibirModalDetalhe(!exibirModalDetalhe);
  }

  function cancelarPedidoCredito(pedidoCredito: IPedidoCreditoResponse){
    Swal.fire({
      title: 'Cancelar o pedido de crédito?',
      text: `Cancelar o pedido # ${pedidoCredito.codigo}`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'VOLTAR',
      confirmButtonText: 'CANCELAR',
      reverseButtons: true,
      customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
          try {
            var cancelarPedidoCredito = {
              idCliente: pedidoCredito.idCliente,
              idPedidoCredito: pedidoCredito.id
            } as ICancelarPedidoCredito

            const { data  } = await ClienteService.cancelarPedidoCredito(cancelarPedidoCredito);
            
            Swal.fire({
              icon: 'success',
              text: `Pedido de crédito cancelado`,
            });
            
            formikFiltro.submitForm();
          } catch (error) {
            Swal.fire(
              Array.isArray(error) ? error[0] : `Não foi possivel cancelar o Pedido de crédito`
              );
          } 
      }
    })
  }

  function enviarPedidoCredito(pedidoCredito: IPedidoCreditoResponse){
    Swal.fire({
      title: 'Enviar o pedido para processamento?',
      text: `Pedidos em processamento não poderão ser alterados, tem certeza?`,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'VOLTAR',
      confirmButtonText: 'ENVIAR',
      reverseButtons: true,
      customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
          try {
            var cancelarPedidoCredito = {
              idCliente: pedidoCredito.idCliente,
              idPedidoCredito: pedidoCredito.id
            } as ICancelarPedidoCredito

            const { data  } = await ClienteService.enviarParaProcessamentoPedidoCredito(cancelarPedidoCredito);
            
            Swal.fire({
              icon: 'success',
              text: `Pedido de crédito enviado com sucesso`,
            });
            
            formikFiltro.submitForm();
          } catch (error) {
            Swal.fire(
              Array.isArray(error) ? error[0] : `Não foi possivel enviar o Pedido de crédito`
              );
          } 
      }
    })
  }

  return (
    <>
      <ConfirmarPedidoEmLoteModal
        toggleModal={toggleModalConfirmarPedidoEmLote}
        exibirModal={exibirModalConfirmarPedidoEmLote}  
        grupoItens={selectGrupos}      
      />

      <PedidoDetalheModal
        toggleModal={toggleModalDetalhe}
        exibirModal={exibirModalDetalhe}
        pedidoCredito={pedidoCreditoSelecionado}
      />

      <ImportarPedidoModal
        toggleModal={toggleModalImportar}
        exibirModal={exibirModalImportar}    
        grupoItens={selectGrupos}    
      />

      <HeaderContent title='Pedidos de crédito'>
            
            <button onClick={() => {
              setPedidoCredito({} as IPedidoCreditoResponse)
              setExibirModalImportar(true)
            }}  className='btn btn-sm btn-primary me-2'>
              <i className="icon-lg text-white bi bi-cloud-arrow-up-fill" style={{fontSize: 18}}></i>
              <span style={{ verticalAlign: 'middle' }}> Importar pedido</span>
            </button>

            <Link to={'/administrador/validar/arquivo/pedido'} className='btn btn-sm btn-primary '>
              <i className="icon-lg text-white bi bi-file-check-fill" style={{fontSize: 18}}></i>
              <span style={{ verticalAlign: 'middle' }}> Validar arquivo</span>
            </Link>
      </HeaderContent>

      <FiltroPedido 
        clienteItens={selectCliente}
        grupoItens={selectGrupos}
        formik={formikFiltro}
        isLoading={loadingInicial}
        toggleModal={toggleModalConfirmarPedidoEmLote}
      />

      {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
            <div className="d-flex flex-row-reverse" style={{opacity: 0.5}}>
              <ul className="list-group list-group-horizontal mb-2">
                <li className="list-group-item"><i style={{fontSize: 20, marginRight: 4}} className="bi bi-people-fill"></i>Funcionários do pedido</li>
                <li className="list-group-item"><i style={{fontSize: 20, marginRight: 4}} className="bi bi-x-square-fill"></i>Cancelar pedido</li>
                <li className="list-group-item "><i style={{fontSize: 20, marginRight: 4}} className="bi bi-box-arrow-up"></i>Confirmar pedido</li>
              </ul>
            </div>
            <GridPadrao colunas={colunas} tipo='Pedido de crédito' itens={pedidosCredito} />
          </div>
        )}
    </>
  )
}